// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArBrokerCustomerLove-module--ar-gradient-blue--5O-lx";
export var arGradientLight = "ArBrokerCustomerLove-module--ar-gradient-light--VF9l5";
export var arH1 = "ArBrokerCustomerLove-module--ar-h1--x26YD";
export var arH1Mobile = "ArBrokerCustomerLove-module--ar-h1-mobile--9lVSy";
export var arH2 = "ArBrokerCustomerLove-module--ar-h2--79ZrG";
export var arH2Mobile = "ArBrokerCustomerLove-module--ar-h2-mobile--5y-xl";
export var arH3 = "ArBrokerCustomerLove-module--ar-h3--IhbFQ";
export var arH3Mobile = "ArBrokerCustomerLove-module--ar-h3-mobile--1aqFs";
export var arH4 = "ArBrokerCustomerLove-module--ar-h4--wKsO0";
export var arH4Mobile = "ArBrokerCustomerLove-module--ar-h4-mobile--8a1Hf";
export var arH5 = "ArBrokerCustomerLove-module--ar-h5--rg3Px";
export var arH5Mobile = "ArBrokerCustomerLove-module--ar-h5-mobile--efWcm";
export var arLinkDesktop = "ArBrokerCustomerLove-module--ar-link-desktop--qjJH1";
export var arLinkMobile = "ArBrokerCustomerLove-module--ar-link-mobile--4lS4i";
export var arParagraphBase = "ArBrokerCustomerLove-module--ar-paragraph-base--FoG+D";
export var arParagraphBaseMedium = "ArBrokerCustomerLove-module--ar-paragraph-base-medium--mRWRl";
export var arParagraphSmall = "ArBrokerCustomerLove-module--ar-paragraph-small--WS4-t";
export var arParagraphSmallMedium = "ArBrokerCustomerLove-module--ar-paragraph-small-medium--6RSTz";
export var arParagraphXl = "ArBrokerCustomerLove-module--ar-paragraph-xl--s4iqp";
export var arParagraphXlMedium = "ArBrokerCustomerLove-module--ar-paragraph-xl-medium--5oQjt";
export var arParagraphXsmall = "ArBrokerCustomerLove-module--ar-paragraph-xsmall--nzoSD";
export var arParagraphXsmallMedium = "ArBrokerCustomerLove-module--ar-paragraph-xsmall-medium--+wDc0";
export var arQuote = "ArBrokerCustomerLove-module--ar-quote---j6kG";
export var arQuoteMobile = "ArBrokerCustomerLove-module--ar-quote-mobile--3Q3Lr";
export var arTransition = "ArBrokerCustomerLove-module--ar-transition--X7eTU";
export var container = "ArBrokerCustomerLove-module--container--VLeyl";
export var footer = "ArBrokerCustomerLove-module--footer--bGIFF";
export var logo = "ArBrokerCustomerLove-module--logo--AJ0jj";
export var quotation = "ArBrokerCustomerLove-module--quotation--mteEn";