// extracted by mini-css-extract-plugin
export var admincontainer = "ArBrokerFeatures-module--admincontainer--TtEH1";
export var arGradientBlue = "ArBrokerFeatures-module--ar-gradient-blue--7u8zO";
export var arGradientLight = "ArBrokerFeatures-module--ar-gradient-light--p1IjH";
export var arH1 = "ArBrokerFeatures-module--ar-h1--rGAXH";
export var arH1Mobile = "ArBrokerFeatures-module--ar-h1-mobile--PtKKF";
export var arH2 = "ArBrokerFeatures-module--ar-h2--FN40g";
export var arH2Mobile = "ArBrokerFeatures-module--ar-h2-mobile--HQ44R";
export var arH3 = "ArBrokerFeatures-module--ar-h3--aLhAq";
export var arH3Mobile = "ArBrokerFeatures-module--ar-h3-mobile--t6ge3";
export var arH4 = "ArBrokerFeatures-module--ar-h4--Ya+rH";
export var arH4Mobile = "ArBrokerFeatures-module--ar-h4-mobile--5Ytvq";
export var arH5 = "ArBrokerFeatures-module--ar-h5--KuNTT";
export var arH5Mobile = "ArBrokerFeatures-module--ar-h5-mobile--lUukA";
export var arLinkDesktop = "ArBrokerFeatures-module--ar-link-desktop--PQOrL";
export var arLinkMobile = "ArBrokerFeatures-module--ar-link-mobile--hkSet";
export var arParagraphBase = "ArBrokerFeatures-module--ar-paragraph-base--lsHI+";
export var arParagraphBaseMedium = "ArBrokerFeatures-module--ar-paragraph-base-medium--7llpk";
export var arParagraphSmall = "ArBrokerFeatures-module--ar-paragraph-small--sYrPx";
export var arParagraphSmallMedium = "ArBrokerFeatures-module--ar-paragraph-small-medium--c9BuG";
export var arParagraphXl = "ArBrokerFeatures-module--ar-paragraph-xl--We8nk";
export var arParagraphXlMedium = "ArBrokerFeatures-module--ar-paragraph-xl-medium--1s7er";
export var arParagraphXsmall = "ArBrokerFeatures-module--ar-paragraph-xsmall--8lUp5";
export var arParagraphXsmallMedium = "ArBrokerFeatures-module--ar-paragraph-xsmall-medium--hQ8-l";
export var arQuote = "ArBrokerFeatures-module--ar-quote--Z9eiB";
export var arQuoteMobile = "ArBrokerFeatures-module--ar-quote-mobile--5U03+";
export var arTransition = "ArBrokerFeatures-module--ar-transition--ln0FA";
export var container = "ArBrokerFeatures-module--container--UkQ85";