// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArBrokerHero-module--ar-gradient-blue--gJ7qC";
export var arGradientLight = "ArBrokerHero-module--ar-gradient-light--GayTE";
export var arH1 = "ArBrokerHero-module--ar-h1--M8-5r";
export var arH1Mobile = "ArBrokerHero-module--ar-h1-mobile--44t14";
export var arH2 = "ArBrokerHero-module--ar-h2--nyE6Z";
export var arH2Mobile = "ArBrokerHero-module--ar-h2-mobile--MLzUw";
export var arH3 = "ArBrokerHero-module--ar-h3--XYcmQ";
export var arH3Mobile = "ArBrokerHero-module--ar-h3-mobile--Mo8+u";
export var arH4 = "ArBrokerHero-module--ar-h4--3N+1w";
export var arH4Mobile = "ArBrokerHero-module--ar-h4-mobile--U3QN4";
export var arH5 = "ArBrokerHero-module--ar-h5--SFfpn";
export var arH5Mobile = "ArBrokerHero-module--ar-h5-mobile--r8viN";
export var arLinkDesktop = "ArBrokerHero-module--ar-link-desktop--2gfWb";
export var arLinkMobile = "ArBrokerHero-module--ar-link-mobile--JVe--";
export var arParagraphBase = "ArBrokerHero-module--ar-paragraph-base--JLQkl";
export var arParagraphBaseMedium = "ArBrokerHero-module--ar-paragraph-base-medium--HnbkO";
export var arParagraphSmall = "ArBrokerHero-module--ar-paragraph-small--ZFxoW";
export var arParagraphSmallMedium = "ArBrokerHero-module--ar-paragraph-small-medium--jkvCB";
export var arParagraphXl = "ArBrokerHero-module--ar-paragraph-xl--3iE9m";
export var arParagraphXlMedium = "ArBrokerHero-module--ar-paragraph-xl-medium--fp5bB";
export var arParagraphXsmall = "ArBrokerHero-module--ar-paragraph-xsmall--J+sBc";
export var arParagraphXsmallMedium = "ArBrokerHero-module--ar-paragraph-xsmall-medium--TPg5k";
export var arQuote = "ArBrokerHero-module--ar-quote--Ipxrv";
export var arQuoteMobile = "ArBrokerHero-module--ar-quote-mobile--B9BqC";
export var arTransition = "ArBrokerHero-module--ar-transition--x99lI";
export var container = "ArBrokerHero-module--container--nILJ7";